<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <v-text-field
            outlined
            :error-messages="nameErrors"
            :label="$t('name')"
            @blur="$v.item.name.$touch()"
            @input="$v.item.name.$touch()"
            required
            v-model="item.name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <v-checkbox
            :error-messages="enabledErrors"
            :label="$t('enabled')"
            @blur="$v.item.enabled.$touch()"
            @input="$v.item.enabled.$touch()"
            v-model="item.enabled"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import has from "lodash/has";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";

export default {
  name: "MenuForm",
  mixins: [validationMixin],
  components: {
  },
  props: {
    values: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {
      },
    },
    initialValues: {
      type: Object,
      default: () => {
      },
    },
    refForm: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: null,
      enabled: false,
    };
  },
  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
    nameErrors() {
      const errors = [];

      if (!this.$v.item.name.$dirty) {
        return errors;
      }

      has(this.violations, "name") && errors.push(this.violations.name);

      !this.$v.item.name.required && errors.push(this.$t("Field is required"));

      return errors;
    },
    enabledErrors() {
      const errors = [];

      if (!this.$v.item.enabled.$dirty) {
        return errors;
      }

      has(this.violations, "enabled") && errors.push(this.violations.enabled);
      return errors;
    },
    violations() {
      return this.errors || {};
    },
  },
  methods: {
  },
  validations: {
    item: {
      name: {
        required,
      },
      enabled: {},
    },
  },
};
</script>
